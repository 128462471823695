import axios from 'axios';
import { toast } from 'react-toastify';
import {
  Get_Upcoming_Vehicles,
  Get_Vehicle_Details,
  compare_vehicle,
  Send_Vehicle_Inquiry,
  config,
  Get_Main_Vehicles,
  Get_Vehicle_Variants,
  Notify_Me,
  State,
  city,
  DealersByVehicleId,
  DealerDeatailsById,
  vehiclesBydealerId,
  Get_Filtered_Vehicle,
  UsersByVehicleId,
  brandsBydealersId,
  dealersByBrandId,
  get_similiar_vehicle,
  User_Reviews,
  get_vehicle_images,
  get_vehicle_videos,
  Get_Vehicles_Color_List,
  getVehicleListReview,
  addQuestionVehicle,
  listQuestionVehicle,
  addAnsToQueVehicle,
  getListOfAnsOfQue,
  VehicleOverviewList,
  get_brochure_url,
  vehicle_details,
  NearbyDealers,
  dealercountWithCity,
  get_all_reviews,
  getVehicleCityPriceBreakup
} from "../api";
import {
  GET_UPCOMING_VEHICLES,
  GET_VEHICLE_DETAILS,
  COMPARE_VEHICLE,
  GET_MAIN_VEHICLES,
  GET_VEHICLE_VARIANTS,
  STATE_NAME,
  CITY_NAME,
  DEALERS_DETAILS,
  DEALERS_DETAILS_BRANDS,
  DEALER_PROFILE,
  DEALER_PROFILE_VEHICLE,
  COMPANY_AVAILABLE_VEHICLES,
  LOAD_COMPANY_AVAILABLE_VEHICLES,
  USER_BY_VEHICLE_ID,
  DEALER_PROFILE_BRANDS,
  GET_SIMILIAR_VEHICLE,
  USER_REVIEWS,
  GET_VEHICLE_COLOR_LIST,
  GET_VEHICLE_LIST_REVIEW,
  GET_VEHICLE_QUESTION_ANS,
  GET_VEHICLE_OVERVIEW_LIST,
  SET_COMPARE_VEHICLE,
} from "./types";
import setAuthToken from "../helpers/setAuthToken";

export const getVehicleDetails = (slug) => async (dispatch) => {
  try {
    const body = JSON.stringify(slug);
    const res = await axios.patch(Get_Vehicle_Details, body, config);
    if (res) {
      dispatch({
        type: GET_VEHICLE_DETAILS,
        payload: res.data.result,
      });
    }
    return res;
  } catch (err) {
    return err;
  }
};
export const getVehicleDetailsById = (id) => async (dispatch) => {
  try {
    // const body = JSON.stringify(slug);
    const res = await axios.patch(vehicle_details + id, config);
    if (res) {
      dispatch({
        type: GET_VEHICLE_DETAILS,
        payload: res.data.result,
      });
    }
    return res;
  } catch (err) {
    return err;
  }
};
export const getVehicleDetailsSelect = (slug) => async (dispatch) => {
  try {
    const body = JSON.stringify(slug);
    const res = await axios.patch(Get_Vehicle_Details, body, config);
    if (res) {
      // dispatch({
      //   type: GET_VEHICLE_DETAILS,
      //   payload: res.data.result,
      // });
    }
    return res;
  } catch (err) {
    return err;
  }
};
export const getCityPriceBreakup = (slug) => async (dispatch) => {
  try {
    const url = `${getVehicleCityPriceBreakup}?vehicleId=${slug.vehicleId}&cityId=${slug.cityId}`;
    const res = await axios.get(url, config);
    return res;
  } catch (err) {
    return err;
  }
};
export const getVehicleListReviewProduct = (vehicleId) => async (dispatch) => {
  try {
    const res = await axios.get(getVehicleListReview + vehicleId);
    if (res) {
      dispatch({
        type: GET_VEHICLE_LIST_REVIEW,
        payload: res.data.result,
      });
    }
    return res;
  } catch (err) {
    return err;
  }
};
export const getUpcomingVehicles = () => async (dispatch) => {
  try {
    const res = await axios.get(Get_Upcoming_Vehicles);
    if (res) {
      dispatch({
        type: GET_UPCOMING_VEHICLES,
        payload: res.data.result,
      });
    }
    return res;
  } catch (err) {
    return err;
  }
};

export const getCompareVehiclesDetails = (id) => async (dispatch) => {
  try {
    const res = await axios.get(compare_vehicle + id);


    if (res) {
      dispatch({
        type: COMPARE_VEHICLE,
        payload: res.data.result,
      });
      /* dispatch({
        type: SET_COMPARE_VEHICLE, 
        payload: res.data.result,
      }); */
    }
    return res;
  } catch (err) {
    return err;
  }
};

export const sendVehicleInquiry = (inquiryData) => async (dispatch) => {
  const body = JSON.stringify(inquiryData);
  try {
    return await axios.post(Send_Vehicle_Inquiry, body, config).then((res) => {
      toast.success(res.data.message);
    });
  } catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.errors);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
};

export const getMainVehicles = (searchParams) => async (dispatch) => {
  try {
    const apiUrl = Get_Main_Vehicles;
    if (searchParams) { apiUrl += `?search=${searchParams}`; }
    const res = await axios.get(apiUrl);
    if (res) {
      dispatch({
        type: GET_MAIN_VEHICLES,
        payload: res.data.result,
      });
    }
    return res;
  } catch (err) {
    return err;
  }
};

export const getVehicleVariants = (_id) => async (dispatch) => {
  try {
    const res = await axios.get(Get_Vehicle_Variants + _id);
    if (res) {
      dispatch({
        type: GET_VEHICLE_VARIANTS,
        payload: res.data.result,
      });
    }
    return res;
  } catch (err) {
    return err;
  }
};
export const getVehicleColorList = (id) => async (dispatch) => {
  try {
    const res = await axios.get(Get_Vehicles_Color_List + "/" + id);

    if (res) {
      dispatch({
        type: GET_VEHICLE_COLOR_LIST,
        payload: res.data.colorVariants,
      });
    }
    return res;
  } catch (err) {
    return err;
  }
};
export const notifyMeForVehicle = (notifyData) => async (dispatch) => {
  const body = JSON.stringify(notifyData);
  try {
    return await axios.post(Notify_Me, body, config).then((res) => {
      toast.success(res.data.message);
    });
  } catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
};
export const getStateName = () => async (dispatch) => {
  const res = await axios.get(State);

  dispatch({
    type: STATE_NAME,
    payload: res.data.result,
  });
  return res;
};

export const getCityName = (stateId) => async (dispatch) => {
  const res = await axios.get(city + stateId);
  dispatch({
    type: CITY_NAME,
    payload: res.data.result,
  });
  return res;
};

export const getDealersByVehicleId =
  (obj) => async (dispatch) => {
    const res = await axios.get(
      DealersByVehicleId +
      "?" + obj
    );
    dispatch({
      type: DEALERS_DETAILS,
      payload: res.data.result,
    });
    return res;
  };

export const getNearbyDealersByVehicleId =
  (obj) => async (dispatch) => {
    const res = await axios.get(
      NearbyDealers +
      "?" + obj
    );
    dispatch({
      type: DEALERS_DETAILS,
      payload: res.data.result,
    });
    return res;
  };

export const getDealersByBrandId = (objData) => async (dispatch) => {
  try {
    const res = await axios.get(dealersByBrandId + objData);
    // Pass objData as the data option
    dispatch({
      type: DEALERS_DETAILS_BRANDS,
      payload: res.data.result,
    });
    return res;
  } catch (error) {
    // Handle error
    console.error('Error:', error);
    throw error; // Re-throw the error to handle it in the caller
  }
};

export const getDealerCountWithCities = (objData, id) => async (dispatch) => {
  const res = await axios.post(dealercountWithCity, {
    "citiesID": objData,
    "company": id
  });
  return res;
};

export const getDealersforBrands = (objData) => async (dispatch) => {
  const res = await axios.get(DealersByVehicleId + objData);
  dispatch({
    type: DEALERS_DETAILS_BRANDS,
    payload: res.data.result,
  });
  return res;
};

export const getDealerDetailsById = (id) => async (dispatch) => {
  const res = await axios.patch(DealerDeatailsById + id);
  dispatch({
    type: DEALER_PROFILE,
    payload: res.data.result,
  });
  return res;
};

export const getVehiclesBydealerId = (id, limit) => async (dispatch) => {
  const res = await axios.get(vehiclesBydealerId + id + "&limit=" + limit);
  dispatch({
    type: DEALER_PROFILE_VEHICLE,
    payload: res.data.result,
  });
  return res;
};

export const getBrandsByDealersId = (id) => async (dispatch) => {
  const res = await axios.get(brandsBydealersId + id);
  dispatch({
    type: DEALER_PROFILE_BRANDS,
    payload: res.data.result,
  });
  return res;
};

export const getCompanyAvailableVehicles =
  (filterDataObj) => async (dispatch) => {
    if (filterDataObj.company) {
      try {
        const res = await axios.get(Get_Filtered_Vehicle, {
          params: filterDataObj,
        });
        if (res) {
          setTimeout(() => {
            dispatch({
              type: COMPANY_AVAILABLE_VEHICLES,
              payload: res.data.result,
            });
          }, 500);
          return res;
        }
      } catch (err) {
        return err;
      }
    }
  };

export const loadCompanyVehicles = () => (dispatch) => {
  dispatch({
    type: LOAD_COMPANY_AVAILABLE_VEHICLES,
  });
};

export const getUsersByVehicleId = (id) => async (dispatch) => {
  const res = await axios.get(UsersByVehicleId + id);
  dispatch({
    type: USER_BY_VEHICLE_ID,
    payload: res.data.result,
  });
  return res;
};

//SIMILIAR PRODUCT API

export const getsimilarvehicle = (data, vehicle) => async (dispatch) => {
  try {
    const res = await axios.get(get_similiar_vehicle, {
      params: data,
    });
    const similarData = res.data.result?.docs?.length > 0 && res.data.result.docs.filter((i, index) => i._id !== vehicle?._id)
    if (res) {
      dispatch({
        type: GET_SIMILIAR_VEHICLE,
        payload: similarData,
      });
    }
  } catch (err) {
    return err;
  }
};

export const getReviews = (vehicle) => async (dispatch) => {
  setAuthToken(
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Imt1c2hhbEBnbWFpbC5jb20iLCJpZCI6IjYyMmYxMzg0ODVlNzRjMjQzM2M4NGVmMCIsImlhdCI6MTY0NzI1MjM5NSwiZXhwIjoxNjc4Nzg4Mzk1fQ.nGKqS_-5AbzcyYlDHbLwSoSHR-xT10RDGOpRpbaNPKE"
  );
  const res = await axios.get(User_Reviews + "?vehicle=" + vehicle);
  if (res) {
    dispatch({
      type: USER_REVIEWS,
      payload: res.data.result,
    });
    return res;
  }
};

export const getAllReviews = async (filter) => {
  const { filterType = 0, page = 1, limit = 10, brandId, productId } = filter || {};

  let apiUrl = `${get_all_reviews}?filterType=${filterType}`;
  if (page) apiUrl += `&page=${page}`;
  if (limit) apiUrl += `&limit=${limit}`;
  if (brandId) apiUrl += `&brandId=${brandId}`;
  if (productId) apiUrl += `&productId=${productId}`;

  try {
    const response = await axios.get(apiUrl);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};


export const postQuestionVehicle = (obj) => async (dispatch) => {
  try {
    let token = localStorage.getItem("token");
    setAuthToken(token);
    return await axios.post(addQuestionVehicle, obj);
  } catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
};
export const postAddAnsToQueVehicle = (obj) => async (dispatch) => {
  try {
    let token = localStorage.getItem("token");
    setAuthToken(token);
    return await axios.post(addAnsToQueVehicle, obj);
  } catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
};
export const getListAllAnsOfQue = (queObj) => async (dispatch) => {
  try {
    const res = await axios.get(getListOfAnsOfQue + queObj);
    return res.data.result;
  } catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
};
export const getListQuestionVehicle = (vehicleObj) => async (dispatch) => {
  try {
    const res = await axios.get(listQuestionVehicle + vehicleObj);
    if (res) {
      dispatch({
        type: GET_VEHICLE_QUESTION_ANS,
        payload: res.data.result,
      });
    }

    return res;
  } catch (error) {
    if (error.response !== undefined) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Something went wrong, Please try again later!");
    }
    return error;
  }
};
export const getVehicleOverviewList = (vehicleId) => async (dispatch) => {
  try {
    const res = await axios.get(VehicleOverviewList + vehicleId);

    if (res) {
      dispatch({
        type: GET_VEHICLE_OVERVIEW_LIST,
        payload: res.data.result,
      });
    }
    return res.data.result;
  } catch (error) {
    // if (error.response !== undefined) {
    //   toast.error(error.response.data.message);
    // } else {
    //   toast.error("Something went wrong, Please try again later!");
    // }
    return error;
  }
};
//get brochure url

export const getBrochureUrl = (id) => async (dispatch) => {
  try {
    const res = await axios.get(get_brochure_url + id);
    return res;
  } catch (err) {
    return err;
  }
};