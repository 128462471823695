import axios from "axios";
import {
  Get_All_Company_List,
  Get_All_Company_List_Without_Pagination,
  Get_Company_Details,
  Get_Company_Infrastructure,
  Get_Random_Companies,
  Get_Vehicles_By_Company,
  config,
} from "../api";
import {
  COMPANY_DETAILS_LOADING,
  GET_ALL_COMPANY_LIST,
  GET_ALL_COMPANY_LIST_WITHOUT_PAGINATION,
  GET_COMPANY_DETAILS,
  GET_COMPANY_INFRASTRUCTURES,
  GET_RANDOM_COMPANY_LIST,
} from "./types";

export const getRandomCompanies = () => async (dispatch) => {
  try {
    const res = await axios.get(Get_Random_Companies);
    if (res) {
      dispatch({
        type: GET_RANDOM_COMPANY_LIST,
        payload: res.data.result,
      });
    }
    return res;
  } catch (err) {
    return err;
  }
};

export const getAllCompanyList = (paginationObj) => async (dispatch) => {
  try {
    const res = await axios.get(Get_All_Company_List, {
      params: paginationObj,
    });
    if (res) {
      dispatch({
        type: GET_ALL_COMPANY_LIST,
        payload: res.data.result,
      });
    }
    return res;
  } catch (err) {
    return err;
  }
};

export const getAllCompanyListWithoutPagination = (searchParams) => async (dispatch) => {
  try {
    const apiUrl = Get_All_Company_List_Without_Pagination;
    if (searchParams) { apiUrl += `?search=${searchParams}`; }
    const res = await axios.get(apiUrl);
    if (res) {
      dispatch({
        type: GET_ALL_COMPANY_LIST_WITHOUT_PAGINATION,
        payload: res.data.result,
      });
    }
    return res;
  } catch (err) {
    return err;
  }
};

export const getVehiclesByCompany = (id) => async (dispatch) => {
  try {
    const res = await axios.get(Get_Vehicles_By_Company + `?company=${id}`);
    return res;
  } catch (err) {
    return err;
  }
};

export const getCompanyDetails = (slug) => async (dispatch) => {
  const body = JSON.stringify(slug);

  companyDetailsLoading(dispatch);
  try {
    const res = await axios.patch(Get_Company_Details, body, config);
    if (res) {
      dispatch({
        type: GET_COMPANY_DETAILS,
        payload: res.data.result,
      });
    }
    return res;
  } catch (err) {
    return err;
  }
};

export const companyDetailsLoading = (dispatch) => {
  dispatch({
    type: COMPANY_DETAILS_LOADING,
  });
};
export const getCompanyInfrastructures = (_id) => async (dispatch) => {
  try {
    const res = await axios.get(Get_Company_Infrastructure + _id);
    if (res) {
      dispatch({
        type: GET_COMPANY_INFRASTRUCTURES,
        payload: res.data.result,
      });
    }
    return res;
  } catch (err) {
    return err;
  }
};
